import {Helpers} from "./helpers";

export class Modal {
    constructor() {
        this.addEventListeners();
        this.modal;
        this.currentElem;
    }

    addEventListeners() {
        const modalItems = [...document.querySelectorAll('.js-open-in-modal')];
        if(modalItems[0].length) {
            modalItems[0].forEach(modalItem => {
                modalItem.addEventListener('click', (e) => {
                    this.open(e.currentTarget);
                    e.currentTarget.scrollIntoView({inline: "center"});
                });
            })

            const close = document.querySelector('.modal__close');
            close.addEventListener('click', () => {
                this.close();
            });
            document.addEventListener("keydown", (e) => {
                if(e.key === "Escape") {
                    this.close();
                }

                if(e.key === "ArrowLeft") {
                    this.previous();
                }

                if(e.key === "ArrowRight") {
                    this.next();
                }
            });
        }
    }

    open(elem) {
        this.modal = document.querySelector('.modal');
        this.currentElem = elem;
        const modalInner = this.modal.querySelector('.modal__inner');
        const clone = elem.cloneNode(true);

        if(this.modal && modalInner){
            Helpers.lockBody();
            this.modal.classList.add('modal--opened');
            modalInner.innerHTML = '';
            modalInner.appendChild(clone);
        }
    }

    close() {
        Helpers.unlockBody();
        this.currentElem = '';

        if(this.modal.classList.contains('modal--opened')){
            this.modal.classList.remove('modal--opened');
        }
    }

    previous() {
        if(this.currentElem) {
            const prevElem = this.currentElem.closest('.js-modal-item').previousElementSibling;

            if(prevElem) {
                const modalInner = this.modal.querySelector('.modal__inner');
                const clone = prevElem.querySelector('.js-open-in-modal').cloneNode(true);
                modalInner.innerHTML = '';
                modalInner.appendChild(clone);
                this.currentElem = prevElem.querySelector('.js-open-in-modal');
            }
        }
    }

    next() {
        if(this.currentElem) {
            const nextElem = this.currentElem.closest('.js-modal-item').nextElementSibling;

            if(nextElem){
                const modalInner = this.modal.querySelector('.modal__inner');
                const clone = nextElem.querySelector('.js-open-in-modal').cloneNode(true);
                modalInner.innerHTML = '';
                modalInner.appendChild(clone);
                this.currentElem = nextElem.querySelector('.js-open-in-modal');
            }
        }
    }
}