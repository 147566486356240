export class Helpers {
    constructor() {}

    static lockBody() {
        document.body.classList.add('body--locked');
    }

    static unlockBody() {
        document.body.classList.remove('body--locked');
    }

}