export class SwipeIndicator {
    constructor() {
        this.swipeParents = [...document.querySelectorAll('.js-swipe-indicator-parent')];

        if(!!this.swipeParents[0].length) {
            this.swipeParents[0].forEach(swipeParent => {
                this.addEventListeners(swipeParent);
            })
        }
    }

    addEventListeners(swipeParent) {
        const swipeElem = swipeParent.querySelector('.js-swipe-indicator');
        swipeParent.addEventListener('touchstart', () => {
            swipeElem.classList.add('swipe-indicator--hidden');
        })
    }
}