import {Helpers} from "./helpers";

export class NavigationMenu {
    constructor() {
        this.addEventListeners();
    }

    addEventListeners(){
        const navigationMenu = document.querySelector('.navigation-menu');
        const hamburgerBtn = document.querySelector('.navigation-menu__hamburger');
        const closeBtn = document.querySelector('.navigation-menu__close');
        if(navigationMenu) {
            hamburgerBtn.addEventListener("click", () => {
                this.open(navigationMenu);
            });

            hamburgerBtn.addEventListener("keydown", (e) => {
                if(e.keyCode === 13) {
                    this.open(navigationMenu);
                }
            });

            closeBtn.addEventListener("click", () => {
                this.close(navigationMenu);
            });

            closeBtn.addEventListener("keydown", (e) => {
                if(e.keyCode === 13) {
                    this.close(navigationMenu);
                }
            });

            document.addEventListener("keydown", (e) => {
                if(e.key === "Escape") {
                    this.close(navigationMenu);
                }
            });
        }
    }

    open(menu) {
        Helpers.lockBody();
        menu.classList.add('navigation-menu--active');
    }

    close(menu) {
        Helpers.unlockBody();
        menu.classList.remove('navigation-menu--active');
    }
}